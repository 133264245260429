import React, { Suspense, useCallback, useEffect, useState } from "react";
import "./ant.css";

import "react-virtualized/styles.css";
import CounterComponent from "../../theme/ui-components/counter/CounterComponent";
import { MenuProductModel } from "../../services/menu_products/models/menu_product.model";
import { useSelector } from "react-redux";
import { getTableId } from "../../react-reduxs/slices/tables/tableSlice";
import { MenuProductAmountType } from "../../models/menu_product_amount_type";
import MenuProductOrderButtonComponent from "../menu_products/MenuProductOrderButtonComponent";
import MenuProductBasketAddComponent from "../menu_products/MenuProductBasketAddComponent";
import { getCompany } from "../../react-reduxs/slices/companies/company_slice";
import UICard from "../../theme/ui-components/cards/UICard";
import { ApiUrl } from "../../consts/api";
import { convertToUnicode } from "../../funcs/func";

import {
  getProductList,
  getProductSelectedCategoryId,
} from "../../react-reduxs/slices/products/product_slice";
import { getCatePageStatus } from "../../react-reduxs/slices/categories/category_slice";
import Template1CategoryPage from "../../pages/companies/components/Template1CategoryPage";
import { Modal, Image } from "antd";

interface ProductTabsProps {}
interface RestaurantProps {
  menuProducts: MenuProductModel[];
}

const Restaurants: React.FC<RestaurantProps> = ({ menuProducts }) => {
  const tableId = useSelector(getTableId);
  const company = useSelector(getCompany);
  const [menuProductAmountListState, setMenuProductAmountListState] = useState<
    MenuProductAmountType[]
  >([]);
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] =
    useState<MenuProductModel | null>(null);
  const [selectProductCount, setSelectProductCount] = useState(1);
  const increment = useCallback((menuProductId: number) => {
    setMenuProductAmountListState((prev) => {
      const updatedList = prev.map((element) =>
        element.menuProductId === menuProductId
          ? { ...element, amount: element.amount + 1 }
          : element
      );
      if (
        !updatedList.some((element) => element.menuProductId === menuProductId)
      ) {
        updatedList.push({ menuProductId, amount: 2 });
      }
      return updatedList;
    });
  }, []);

  const descrement = useCallback((menuProductId: number) => {
    setMenuProductAmountListState((prev) => {
      const updatedList = prev.map((element) =>
        element.menuProductId === menuProductId && element.amount > 1
          ? { ...element, amount: element.amount - 1 }
          : element
      );
      if (
        !updatedList.some((element) => element.menuProductId === menuProductId)
      ) {
        updatedList.push({ menuProductId, amount: 2 });
      }
      return updatedList;
    });
  }, []);

  const removeMenuProductAmount = useCallback((menuProductId: number) => {
    setMenuProductAmountListState((prev) =>
      prev.filter((x) => x.menuProductId !== menuProductId)
    );
  }, []);

  return (
    <div
      id="restaurants-section"
      className="menu-section"
      style={{
        marginTop: -5,
        display: "flex",
        flexWrap: "wrap",
        gap: 8,
        marginLeft: 6,
        justifyContent: "center",
      }}
    >
      {menuProducts?.map((restaurant, i) => {
        const count =
          menuProductAmountListState.find(
            (x) => x.menuProductId === restaurant.id
          )?.amount ?? 1;
        return (
          <UICard
            key={restaurant.id}
            OnClick={() => {
              setSelectedProduct(restaurant);
              setDetailModalVisible(true);
            }}
            imageSrc={
              restaurant.photo
                ? `${ApiUrl}/${restaurant.photo}`
                : "/images/product.png"
            }
            title={restaurant.title}
            price={`${(count * restaurant.price).toFixed(2)} ${convertToUnicode(
              company?.currency?.symbol ?? ""
            )}`}
            RigthIcon={
              <MenuProductBasketAddComponent title="Sepet "
                menuProduct={restaurant}
                menuProductAmountList={menuProductAmountListState}
                removeMenuProductAmount={removeMenuProductAmount}
                tableId={tableId}
              />
            }
            description={restaurant.detail}
            Footer={
              <div style={{ display: "flex", gap: 5 }}>
                <CounterComponent
                  count={count}
                  increment={() => increment(restaurant.id)}
                  descrement={() => descrement(restaurant.id)}
                />
                <MenuProductOrderButtonComponent title="Sipariş"
                  menuProduct={restaurant}
                  removeMenuProductAmount={removeMenuProductAmount}
                  menuProductAmountList={menuProductAmountListState}
                  tableId={tableId}
                />
              </div>
            }
          />
        );
      })}
      <Modal
        closeIcon={
          <i
            style={{ color: "white", fontSize: "1.4em" }}
            className="fa-duotone fa-circle-xmark "
          ></i>
        }
        styles={{
          content: {
           
          },
        }}
       
        open={detailModalVisible} classNames={{
          content:"my-content",
        }}
        footer={null}
        onCancel={() => {
          setSelectedProduct(null);
          setDetailModalVisible(false);
          setSelectProductCount(1);
        }}
      >
        {selectedProduct && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ marginBottom: 10 }}>{selectedProduct.title}</div>
            <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
              <Image
                height={150}
                width={150}
                style={{ objectFit: "contain", minWidth: 150 }}
                src={`${ApiUrl}/${selectedProduct.photo}`}
              />
              <div style={{ color: "white", maxHeight: 150, overflow: "auto" }}>
                {selectedProduct.calorie !== 0 && (
                  <h5> Kalori :{selectedProduct.calorie}</h5>
                )}
                {selectedProduct.allergenicInformation && (
                  <h5>
                    Alerjen Bilgisi :{selectedProduct.allergenicInformation}
                  </h5>
                )}
                {selectedProduct.timeToGetReady && (
                  <h5>Hazırlanma Süresi :{selectedProduct.timeToGetReady}</h5>
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                maxHeight: 150,
                overflow: "auto",
              }}
            >
              <h5 style={{ flex: 1 }}>{selectedProduct.detail}</h5>
            </div>
            <div style={{ display: "flex", gap: 5 }}>
              <CounterComponent
                count={selectProductCount}
                increment={() => setSelectProductCount(selectProductCount + 1)}
                descrement={() => {
                  if (selectProductCount > 1) {
                    setSelectProductCount(selectProductCount - 1);
                  }
                }}
              />
              <MenuProductOrderButtonComponent
                menuProduct={selectedProduct}
                removeMenuProductAmount={removeMenuProductAmount}
                menuProductAmountList={menuProductAmountListState}
                title="Sipariş Ver"
                tableId={tableId}
              />
              <MenuProductBasketAddComponent
                title="Sepet"
                menuProduct={selectedProduct}
                menuProductAmountList={menuProductAmountListState}
                removeMenuProductAmount={removeMenuProductAmount}
                tableId={tableId}
              />
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

const ProductTabs: React.FC<ProductTabsProps> = () => {
  const categoryPageStatus = useSelector(getCatePageStatus);
  const categoryIdState = useSelector(getProductSelectedCategoryId);

  const productListState = useSelector(getProductList);

  const [menuProductList, setMenuProductList] = useState<MenuProductModel[]>(
    []
  );

  useEffect(() => {
    if (productListState && categoryIdState) {
      const filteredProducts = productListState.filter(
        (product) => product.menuCategoryId === categoryIdState
      );
      setMenuProductList(filteredProducts);
    } else {
      setMenuProductList([]);
    }
  }, [categoryIdState, productListState]);

  return (
    <div className="m-container">
      <div className={categoryPageStatus === true ? "m-content2" : "m-content"}>
        {categoryPageStatus ? (
          <Template1CategoryPage />
        ) : (
          <>
            <Suspense fallback={<div>Loading...</div>}>
              <Restaurants menuProducts={menuProductList} />
            </Suspense>
          </>
        )}
      </div>
    </div>
  );
};

export default ProductTabs;
