import BaseApiService from "../base_api.service";
import { CompanyUserDto } from "./models/company_user.dto";
import { CompanyUserModel } from "./models/company_user.model";

export class CompanyUserService extends BaseApiService {
  /**
   *
   */
  constructor() {
    super("CompanyUsers");
  }

  async getCompanyUserListByCompanyId(
    companyId: number
  ): Promise<CompanyUserModel[]> {
    let response = await this.get<CompanyUserModel[]>(
      `?companyId=${companyId}`
    );
    return response ?? [];
  }
  addCompanyUserList(
    companyUserModel: CompanyUserDto
  ): Promise<string | undefined> {
    return this.post<string>(`/AddList`, companyUserModel);
  }
  deleteCompanyUserList(
    companyUserModel: CompanyUserDto
  ): Promise<string | undefined> {
    return this.post<string>(`/DeleteList`, companyUserModel);
  }
}
