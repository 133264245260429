import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../configure_store";

interface AuthState {
  companyId: number;
  userId: number;
  token: string;
}

const initialState: AuthState = {
  companyId: 0,
  userId: 0,
  token: "",
};
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserId: (state, action: PayloadAction<number>) => {
      state.userId = action.payload;
    },
    setCompanyId: (state, action: PayloadAction<number>) => {
      state.companyId = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    loginOut: (state, action: PayloadAction) => {
      state.companyId = 0;
      state.userId = 0;
      state.token="";
      sessionStorage.clear();
    },
  },
});

export const { setUserId, setCompanyId, setToken,loginOut } = authSlice.actions;

export const getUserId = (state: RootState) => state.auth.userId;
export const getCompanyId = (state: RootState) => state.auth.companyId;
export const getToken = (state: RootState) => state.auth.token;
const AuthReducer = authSlice.reducer;
export default AuthReducer;
