import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../configure_store";
import { MenuCategoryModel } from "../../../services/menu_categories/models/menu_category.model";

interface CategoryState {
  categoryPageStatus: boolean;
  categoryList: MenuCategoryModel[];
}

const initialState: CategoryState = {
  categoryList: [],
  categoryPageStatus: true,
};
const categorySlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setCategoryList: (state, action: PayloadAction<MenuCategoryModel[]>) => {
      state.categoryList = action.payload;
    },
    setCategoryPageStatus: (state, action: PayloadAction<boolean>) => {
      state.categoryPageStatus = action.payload;
    },
  },
});

export const { setCategoryList, setCategoryPageStatus } = categorySlice.actions;

export const getCategoryList = (state: RootState) =>
  state.category.categoryList;
export const getCatePageStatus = (state: RootState) =>
  state.category.categoryPageStatus;

const CategoryReducer = categorySlice.reducer;
export default CategoryReducer;
