import { Dropdown, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { I18nPagePropertyEnum } from "../i18n/i18n_page_property";
import {
  getToken,
  loginOut,
  setCompanyId,
} from "../react-reduxs/slices/auth/auth_slice";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "../react-reduxs/configure_store";
import {
  setCompanyList,
  setCompanyPublic,
} from "../react-reduxs/slices/companies/company_slice";
import PasswordReset from "./PasswordReset";

const UserProfileComponent = () => {
  const token = useSelector(getToken);
  const [userName, setUserName] = useState<string>();
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  useEffect(() => {
    if (token) {
      let decodeToken = JSON.parse(atob(token.split(".")[1]));
      if (decodeToken) {
        setUserName(
          decodeToken[
            "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
          ]
        );
      }
    }
    return () => {};
  }, [token]);
  return (
    <div>
      <Dropdown.Button
        menu={{
          items: [
            {
              key: 1,
              label: t(I18nPagePropertyEnum.commonLogOut),
              icon: <i className="fa-duotone fa-arrow-left-from-arc"></i>,
              onClick: () => {
                dispatch(loginOut());
                dispatch(setCompanyPublic(undefined));
                dispatch(setCompanyList([]));
                dispatch(setCompanyId(0));
              },
            },
            {
              key: 2,
              label: "Şifreni Yenile",
              icon: <i className="fa-duotone fa-key"></i>,
              onClick: () => {
                setModalVisible(true);
              },
            },
          ],
        }}
        placement="bottom"
        icon={<i className="fa-duotone fa-user"></i>}
      >
        {userName ?? " name found"}
      </Dropdown.Button>
      {modalVisible ? (
        <Modal
          open={modalVisible}
          footer={<></>}
          onCancel={() => setModalVisible(false)}
        >
          <PasswordReset />
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
};

export default UserProfileComponent;
