import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Provider, useSelector } from "react-redux";
import { getCompanyId } from "../../../react-reduxs/slices/auth/auth_slice";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
} from "antd";
import { MenuCategoryModel } from "../../../services/menu_categories/models/menu_category.model";
import { MenuProductModel } from "../../../services/menu_products/models/menu_product.model";
import Title from "antd/es/typography/Title";
import CompManagerMenuCategoryAdd from "../menu_categories/CompManagerMenuCategoryAdd";
import FileUploadComponent from "../../tests/FileUploadComponent";
import { getCompany } from "../../../react-reduxs/slices/companies/company_slice";
import { store } from "../../../react-reduxs/configure_store";
import MenuProductServcie from "../../../services/menu_products/menu_product.service";
import { ApiUrl } from "../../../consts/api";
import { FileUploadService } from "../../../services/file_uploads/file_upload.service";

interface CompManagerMenuProductAddProps {
  closeModal: () => void;
  categoryList: MenuCategoryModel[];
  saveProduct: (value: MenuProductModel) => void;
  saveCategory: (value: MenuCategoryModel) => void;
  deleteMenuProductImage: (
    menuProductModel: MenuProductModel,
    imagePath: string
  ) => Promise<MenuProductModel | undefined>;
  id: number;
  productService: MenuProductServcie;
  isLoading: boolean;
}
const CompManagerMenuProductAdd: React.FC<CompManagerMenuProductAddProps> = ({
  categoryList,
  saveProduct,
  saveCategory,
  closeModal,
  id,
  productService,
  deleteMenuProductImage,
  isLoading,
}) => {
  const [count, setCount] = useState(0);

  const modalInstance = useRef<any>();
  const menuProductModel = useRef<MenuProductModel>();
  const fileUploadService = useMemo(() => new FileUploadService(), []);
  const companyId = useSelector(getCompanyId);
  const company = useSelector(getCompany);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [form] = Form.useForm();
  const getProduct = useCallback(async () => {
    let response = await productService.getById(id);

    if (response) {
      menuProductModel.current = response;
      form.setFieldValue("id", response.id);
      form.setFieldValue("menuCategoryId", response.menuCategoryId);
      form.setFieldValue("price", response.price);
      form.setFieldValue("title", response.title);
      form.setFieldValue(
        "allergenicInformation",
        response.allergenicInformation
      );
      form.setFieldValue("calorie", response.calorie);
      form.setFieldValue("detail", response.detail);
      form.setFieldValue("timeToGetReady", response.timeToGetReady);

      setImageUrl(response.photo ?? "");
    }
  }, [form, id, productService]);

  const onFinish = async (values: any) => {
    if (companyId) {
      await saveProduct({
        companyId: companyId,
        photo: imageUrl === "" ? undefined : imageUrl,

        ...values,
      });
      setImageUrl("");
      closeModal();
    }
  };

  const [imageUrl, setImageUrl] = useState("");
  useEffect(() => {
    if (id !== 0) {
      setCount((co) => {
        if (co === 0) {
          getProduct();
        }
        return 1;
      });
    }

    return () => {
      // if (imageUrl!==undefined && imageUrl!=="") {
      //   fileUploadService.deleteFile(imageUrl);
      // }
    };
  }, [company, fileUploadService, getProduct, id, imageUrl]);

  return (
    <div>
      <Title level={3}>Ürün Ekle</Title>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item name="id" label="">
          <Input placeholder="" style={{ display: "none" }} />
        </Form.Item>
        <Row style={{ gap: 3 }}>
          <Col>
            <Col flex={1}>
              {menuProductModel.current?.photo !== "" &&
              menuProductModel.current?.photo !== undefined ? (
                <div>
                  <Button
                    size="small"
                    style={{ position: "absolute", left: 80 }}
                    loading={isLoading}
                    onClick={async () => {
                      if (menuProductModel.current) {
                        console.error(menuProductModel.current);

                        if (
                          menuProductModel.current.photo !== undefined &&
                          menuProductModel.current.photo !== ""
                        ) {
                          let response = await deleteMenuProductImage(
                            menuProductModel.current,
                            menuProductModel.current.photo ?? ""
                          );
                          if (response) {
                            setImageUrl("");
                            menuProductModel.current = response;
                          }
                        }
                      }
                    }}
                    danger
                    icon={<i className="fa-duotone fa-trash"></i>}
                  />
                  <img
                    width={110}
                    height={110}
                    src={ApiUrl + "/" + imageUrl}
                    alt=""
                  />
                </div>
              ) : (
                <FileUploadComponent
                  path={"Companies/" + company?.folderName + "/Products"}
                  setImageUrlParam={setImageUrl}
                  setDeleteImageParam={setDeleteStatus}
                />
              )}
            </Col>
          </Col>
          <Col flex={1}>
            <Form.Item name="title" label="Adı" rules={[{ required: true }]}>
              <Input placeholder="Adı" />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item name="price" label="Fiyat" rules={[{ required: true }]}>
              <InputNumber style={{ minWidth: 150 }} placeholder="Fiyat" />
            </Form.Item>
          </Col>

          <Col span={16}>
            <Form.Item
              name="menuCategoryId"
              label="Kategori "
              rules={[{ required: true }]}
            >
              <Select
                placeholder=" "
                style={{ width: "100%", minWidth: 150 }}
                options={categoryList.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
              />
            </Form.Item>
          </Col>
          <Col
            flex={1}
            style={{ display: "flex", alignItems: "center", paddingTop: 7 }}
          >
            <Button
              loading={isLoading}
              disabled={isLoading}
              onClick={() => {
                modalInstance.current = Modal.info({
                  footer: <></>,
                  content: (
                    <Provider store={store}>
                      <CompManagerMenuCategoryAdd
                        isLoading={isLoading}
                        saveCategory={saveCategory}
                        modalClose={() => {
                          modalInstance.current?.destroy();
                        }}
                      />
                    </Provider>
                  ),
                });
              }}
              icon={<i className="fa-duotone fa-plus"></i>}
            >
              Kategori Ekle
            </Button>
          </Col>
          <Col>
            <Form.Item name="calorie" label="Kalori" initialValue={0}>
              <InputNumber
                placeholder="Kalori"
                onChange={(e) => {
                  if (e == null) {
                    form.setFieldValue("calorie", 0);
                  }
                }}
              />
            </Form.Item>
          </Col>

          <Col flex={1}>
            <Form.Item name="timeToGetReady" label="Hazırlanma zamanı" initialValue={""}>
              <Input placeholder="Hazırlanma zamanı"  />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="allergenicInformation" label="Alerjen Bilgisi" initialValue={"0"}>
              <Input.TextArea placeholder="Alerjen Bilgisi" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="detail" label="Detay" initialValue={""}>
              <Input.TextArea placeholder="Detay" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item>
              <Space>
                <Button
                  disabled={isLoading}
                  loading={isLoading}
                  type="primary"
                  htmlType="submit"
                >
                  Kaydet
                </Button>
              </Space>
            </Form.Item>
          </Col>
          <Col style={{paddingLeft:10}}>
            <Button
              disabled={deleteStatus}
              danger
              htmlType="button"
              onClick={() => {
                closeModal();
              }}
            >
              İptal
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CompManagerMenuProductAdd;
