export const I18nPageProperty = {
  waiterLayout: {
    order: {
      en: "Orders",
      tr: "Siparişler",
    },
  },
  common: {
    pendingApproval: {
      en: "Pending Approval",
      tr: "Onay bekleyen",
    },
    approved: {
      en: "Approved",
      tr: "Onaylandı",
    },
    itIsCancelled: {
      en: "It is cancelled",
      tr: "İptal edildi",
    },
    orderIsBeingPrepared: {
      en: "Order is being prepared",
      tr: "Sipariş hazırlanıyor",
    },
    logOut: {
      en: "Log Out",
      tr: "Çıkış Yap",
    },
    ok: {
      en: "Ok",
      tr: "Tamam",
    },
    cancel: {
      en: "Cancel",
      tr: "İptal",
    },
    orders: {
      en: "Orders",
      tr: "Siparişler",
    },
    amount: {
      en: "Amount",
      tr: "Adet",
    },
    sendOrder: {
      en: "Order now",
      tr: "Sipariş ver",
    },
    paymentNotMade: {
      en: "Payment not received",
      tr: "Ödeme alınmadı",
    },
    paymentCompleted: {
      en: "Payment completed",
      tr: "Ödeme tamamlandı",
    },
  },
  waiter: {
    confirmAllOrders: {
      en: "Confirm all orders",
      tr: "Tüm siparişleri onayla",
    },
    tableDetailTitle: {
      en: "Table {{number}}'s order list",
      tr: "Masa {{number}}'in sipariş listesi",
    },

    theWaiterIsCalling: {
      en: "The waiter is calling",
      tr: "Garson çağırıyor",
    },
  },
  login: {
    title: {
      en: "Restaurant Menu",
      tr: "Restoran Menu",
    },
    userName: {
      en: "User name",
      tr: "Kullanıcı Adı",
    },
    email: {
      en: "E-mail",
      tr: "E-posta",
    },
    password: {
      en: "Password",
      tr: "Şifre",
    },
    login: {
      en: "Login",
      tr: "Giriş",
    },
    language: {
      en: "Language",
      tr: "Dil ",
    },
  },
  menuProduct: {
    orderNote: {
      en: "Note",
      tr: "Not:",
    },
    addToBasket: {
      en: "Add to basket",
      tr: "Sepete ekle",
    },
    waiter: {
      en: "Waiter",
      tr: "Garson",
    },
    callTheWaiter: {
      en: "The waiter will be called to the table?",
      tr: "Garson Masaya çağrılsın mı?",
    },
  },
  template: {
    waiterFloat: {
      en: "Waiter",
      tr: "Garson",
    },
    tableFloat: {
      en: "Table",
      tr: "Masa",
    },
    basketFloat: {
      en: "Basket",
      tr: "Sepet",
    },
  },
};
export enum I18nPagePropertyEnum {
  waiterLayoutOrder = "waiterLayout.order",
  //#region common
  commonPendingApproval = "common.pendingApproval",
  commonApproved = "common.approved",
  commonItIsCancelled = "common.itIsCancelled",
  commonOrderIsBeingPrepared = "common.orderIsBeingPrepared",
  commonPaymentNotMade="common.paymentNotMade",
  commonPaymentCompleted="common.paymentCompleted",
  commonOk = "common.ok",
  commonCancel = "common.cancel",
  commonAmount = "common.amount",
  commonLogOut = "common.logOut",
  commonSendOrder = "common.sendOrder",
  //#endregion
  //#region waiter
  waiterConfirmAllOrders = "waiter.confirmAllOrders",
  waiterTableDetailTitle = "waiter.tableDetailTitle",
  waiterTheWaiterIsCalling = "waiter.theWaiterIsCalling",
  //#endregion
  //#region login
  loginTitle = "login.title",
  loginUserName = "login.userName",
  loginEmail = "login.email",
  loginPassword = "login.password",
  loginLogin = "login.login",
  loginLanguage = "login.language",
  //#endregion
  //#region menuProduct
  menuProductOrderNote = "menuProduct.orderNote",
  menuProductAddToBasket = "menuProduct.addToBasket",
  menuProductWaiter = "menuProduct.waiter",
  menuProductCallTheWaiter = "menuProduct.callTheWaiter",
  //#endregion
  //#region  template
  templateWaiterFloat = "template.waiterFloat",
  templateTableFloat = "template.tableFloat",
  templateBasketFloat = "template.basketFloat",
  //#endregion
}
