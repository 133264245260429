import { Button } from "antd";
import React, { useCallback, useState } from "react";
import { BasketModel } from "../../../models/baskets/basket.model";
import * as signalR from "@microsoft/signalr";
import { useDispatch, useSelector } from "react-redux";
import { getTableId } from "../../../react-reduxs/slices/tables/tableSlice";
import {
  OrderProductStatusEnum,
  OrderStatusEnum,
} from "../../../enums/orders/order_status.enum";
import { OrderProductModel } from "../../../services/order_products/models/order_product.model";
import { getCompany } from "../../../react-reduxs/slices/companies/company_slice";
import { AppDispatch } from "../../../react-reduxs/configure_store";
import { clear } from "../../../react-reduxs/slices/baskets/basker-slice";
import { OrderHubUrl } from "../../../consts/api";
import { OrderHubEnum } from "../../../enums/order_hub.enum";

interface BasketOrdersSendComponentProps {
  basketList: BasketModel[];
}

const BasketOrdersSendComponent: React.FC<BasketOrdersSendComponentProps> = ({
  basketList = [],
}) => {
  const dispatch: AppDispatch = useDispatch();
  const tableId = useSelector(getTableId);
  const company = useSelector(getCompany);
  const connection = new signalR.HubConnectionBuilder()
    .withUrl(OrderHubUrl(company?.id ?? 0).url).withAutomaticReconnect()
    .configureLogging(signalR.LogLevel.Information)
    .build();

  const senOrder = useCallback(async () => {
    await connection
      .start()
      .then(() => {
        console.log("SignalR bağlantısı kuruldu.");
      })
      .catch((err) => {
        console.error(err.toString());
        connection.stop();
      });
    try {
      await connection.invoke(
        OrderHubEnum.placeOrder,
        {
          tableId: tableId,
          currencyId: company?.currencyId,
          companyId: company?.id,
          mobilePhone: "",
          status: OrderStatusEnum.paymentNotMade,
        },
        [
          ...basketList.map((x) => {
            return {
              amount: x.amount,
              menuProductId: x.menuProductId,
              menuProductName: x.menuProductName,
              menuProductPhoto: x.menuProductPhoto,
              menuProductPrice: x.price,
              status: OrderProductStatusEnum.pendingApproval,
              orderNote: x.orderNote,
            };
          }),
        ]
      );
      dispatch(clear());
    } catch (error) {
      console.error("Basket Order send", error);
    } finally {
      connection.stop();
    }

    // dispatch(orderSetOrderProduct([...ddd.orderProductList]));
  }, [basketList, connection, tableId]);
  return (
    <div>
      <Button
        type="dashed"
        style={{ backgroundColor: "red", color: "white" }}
        onClick={() => {
          senOrder();
        }}
      >
        Siparişleri Ver
      </Button>
    </div>
  );
};

export default BasketOrdersSendComponent;
