import React, { useCallback, useEffect, useMemo, useState } from "react";
import MenuCategoryService from "../../../services/menu_categories/menu_category.service";
import MenuProductServcie from "../../../services/menu_products/menu_product.service";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyId } from "../../../react-reduxs/slices/auth/auth_slice";
import {
  setCategoryList,
  setCategoryPageStatus,
} from "../../../react-reduxs/slices/categories/category_slice";
import {
  categoryIdChange,
  setProductList,
} from "../../../react-reduxs/slices/products/product_slice";
import { MenuCategoryModel } from "../../../services/menu_categories/models/menu_category.model";
import { Spin } from "antd";
import { ApiUrl } from "../../../consts/api";
import Title from "antd/es/typography/Title";

const Template1CategoryPage = () => {
  const dispatch = useDispatch();
  const categoryService = useMemo(() => new MenuCategoryService(), []);
  const productService = useMemo(() => new MenuProductServcie(), []);
  const companyId = useSelector(getCompanyId);
  const [count, setCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [category, setCategory] = useState<MenuCategoryModel[]>([]);
  const initLoad = useCallback(async () => {
    setIsLoading(true);
    let response = await categoryService.getList(companyId);
    let productResponse = await productService.getList(companyId);
    if (response) {
      dispatch(setCategoryList(response));
      setCategory(response);
    } else {
      dispatch(setCategoryList([]));
      setCategory([]);
    }
    if (productResponse) {
      dispatch(setProductList(productResponse));
    } else {
      dispatch(setProductList([]));
    }
    setIsLoading(false);
  }, [categoryService, companyId, dispatch, productService]);

  useEffect(() => {
    if (companyId !== 0) {
      setCount((ct) => {
        if (ct === 0) {
          initLoad();
        }
        return ct;
      });
    }

    return () => {};
  }, [companyId, initLoad]);

  const selectCategory = useCallback(
    async (categoryId: number) => {
      // Sort the categories
      const sortedCategories = [...category].sort((a, b) =>
        a.id === categoryId ? -1 : b.id === categoryId ? 1 : 0
      );
      // Dispatch the sorted category list
      dispatch(setCategoryList(sortedCategories));
      dispatch(categoryIdChange(categoryId));
      dispatch(setCategoryPageStatus(false));
    },
    [category, dispatch]
  );

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100vh - 80px)",
        }}
      >
        <Spin
          indicator={<i className="fa-duotone fa-loader custom-spin-icon"></i>}
          tip="Yükleniyor..."
          size="large"
        >
          <div style={{ width: 100 }}></div>
        </Spin>
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: 10,
          justifyContent: "center",
          paddingTop: 10,
          maxHeight: "calc(100vh - 80px)",
          overflowY: "auto",
        }}
      >
        {category.map((x) => (
          <div key={x.id}
            onClick={() => selectCategory(x.id)}
            style={{
              cursor: "pointer",
              padding: 10,
              borderRadius: 10,
              backgroundColor: "#313131AB",
              maxWidth: 165,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <img
              style={{ objectFit: "scale-down" }}
              height={100}
              width={150}
              alt="example"
              src={
                x.photo !== undefined && x.photo !== ""
                  ? ApiUrl + "/" + x.photo
                  : "/images/category.jpg"
              }
            />
            <Title
              style={{
                color: "white",
                textAlign: "center",
                fontSize: 14,
                fontWeight: "bold",
                fontFamily: "Sofia",
                lineHeight: 1,
                marginBlockStart: 5,
              }}
              level={5}
            >
              {x.name}
            </Title>
          </div>
        ))}
      </div>
    );
  }
};

export default Template1CategoryPage;
