import BaseApiService from "../base_api.service";
import { MenuCategoryModel } from "./models/menu_category.model";

class MenuCategoryService extends BaseApiService {
  constructor() {
    super("MenuCategories");
  }
  async getList(companyId: number): Promise<Promise<MenuCategoryModel[]>> {
    return (
      (await this.get<MenuCategoryModel[]>(`/?companyId=${companyId}`)) ?? []
    );
  }
  add(
    menuCategoryModel: MenuCategoryModel
  ): Promise<MenuCategoryModel | undefined> {
    console.error(menuCategoryModel, "service");

    return this.post<MenuCategoryModel>(``, menuCategoryModel);
  }
  update(
    menuCategoryModel: MenuCategoryModel
  ): Promise<MenuCategoryModel | undefined> {
    console.error(menuCategoryModel, "service");

    return this.put<MenuCategoryModel>(``, menuCategoryModel);
  }
  deleteCategory(id: number): Promise<string | undefined> {
    return this.delete<string>(`?id=${id}`);
  }
}
export default MenuCategoryService;
