import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Avatar, Popconfirm, Space, Tooltip } from "antd";

import ProductTabs from "../../../components/tabs/ProductTabs";
import "../QRMenu.scss";
import BasketComponent from "../../../components/baskets/BasketComponent";
import CompanyLogoComponent from "../components/CompanyLogoComponent";
import { AppDispatch } from "../../../react-reduxs/configure_store";
import { useDispatch, useSelector } from "react-redux";
import CompanyTableOrderPage from "../tables/CompanyTableOrderPage";
import { useTranslation } from "react-i18next";
import { I18nPagePropertyEnum } from "../../../i18n/i18n_page_property";

import * as signalR from "@microsoft/signalr";
import { setOrder } from "../../../react-reduxs/slices/orders/order_slice";
import { OrderService } from "../../../services/orders/order.service";
import { ApiUrl, OrderHubUrl, WaiterHubUrl } from "../../../consts/api";
import { getCompanyId } from "../../../react-reduxs/slices/auth/auth_slice";
import { getCompany } from "../../../react-reduxs/slices/companies/company_slice";
import { OrderHubEnum } from "../../../enums/order_hub.enum";
import { getTableId } from "../../../react-reduxs/slices/tables/tableSlice";
import { toast } from "react-toastify";
import {
  getCatePageStatus,
  getCategoryList,
  setCategoryPageStatus,
} from "../../../react-reduxs/slices/categories/category_slice";
import { categoryIdChange } from "../../../react-reduxs/slices/products/product_slice";
import CategoryComponent from "../../../components/tabs/CategoryComponent";

enum UserStatus {
  LoggedIn = "Logged In",
  LoggingIn = "Logging In",
  LoggedOut = "Logged Out",
  LogInError = "Log In Error",
  VerifyingLogIn = "Verifying Log In",
}

interface IAppContext {
  userStatus: UserStatus;
  setUserStatusTo: (status: UserStatus) => void;
}
interface ITimeUtility {
  format: (date: Date) => string;
  formatHours: (hours: number) => string;
  formatSegment: (segment: number) => string;
}

const T: ITimeUtility = {
  format: (date: Date): string => {
    const hours: string = T.formatHours(date.getHours());
    const minutes: string = T.formatSegment(date.getMinutes());

    return `${hours}:${minutes}`;
  },
  formatHours: (hours: number): string => {
    return hours % 12 === 0 ? "12" : (hours % 12).toString();
  },
  formatSegment: (segment: number): string => {
    return segment < 10 ? `0${segment}` : segment.toString();
  },
};

const Menu: React.FC<MenuProps> = () => {
  return (
    <div id="app-menu">
      <div id="app-menu-content-wrapper" style={{}}>
        <div id="app-menu-content">
          <ProductTabs></ProductTabs>
        </div>
      </div>
    </div>
  );
};

const Background: React.FC = () => {
  const company = useSelector(getCompany);
  const { userStatus, setUserStatusTo } = React.useContext(AppContext);

  const handleOnClick = (): void => {
    if (userStatus === UserStatus.LoggedOut) {
      setUserStatusTo(UserStatus.LoggingIn);
    }
  };

  return (
    <div id="app-background" onClick={handleOnClick}>
      <div
        id="app-background-image"
        style={{
          backgroundColor: company?.backgroundImage
            ? company?.backgroundImage.search("/color:/")
              ? company?.backgroundImage.replaceAll("color:", "")
              : "white"
            : "white",
          backgroundImage: `url(${
            company?.backgroundImage
              ? ApiUrl + "/" + company?.backgroundImage
              : "https://images.unsplash.com/photo-1604076913837-52ab5629fba9?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          })`,
        }}
        className="background-image"
      />
    </div>
  );
};

const AppContext = React.createContext<IAppContext>({
  userStatus: UserStatus.LoggedOut,
  setUserStatusTo: () => {},
});
const QrTemplate1Page: React.FC = () => {
  const [count, setCount] = useState<number>(0);
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const companyId = useSelector(getCompanyId);
  const company = useSelector(getCompany);
  const tableId = useSelector(getTableId);

  const categoryList = useSelector(getCategoryList);
  const [tooltipVisble, setTooltipVisble] = useState<boolean>(false);
  const categoryPageStatus = useSelector(getCatePageStatus);

  const [userStatus, setUserStatusTo] = useState<UserStatus>(
    UserStatus.LoggedIn
  );
  const orderService = useMemo(() => new OrderService(), []);

  const getStatusClass = (): string => {
    return userStatus.replace(/\s+/g, "-").toLowerCase();
  };
  const getOrder = useCallback(async () => {
    try {
      let orderResponse = await orderService.getOrder(companyId, tableId ?? "");

      if (orderResponse) {
        dispatch(setOrder(orderResponse));
      } else {
        dispatch(setOrder({} as any));
      }
      console.error(orderResponse);
    } catch (error) {
      console.error("OrderService Qery", error);
    }
  }, [companyId, dispatch, orderService, tableId]);

  const getOrderHup = useCallback(
    async (companyId: number) => {
      console.error(companyId);
      if (companyId !== 0) {
        let connection = new signalR.HubConnectionBuilder()
          .withUrl(OrderHubUrl(companyId).url)
          .withAutomaticReconnect()
          .build();

        const startConnection = async () => {
          try {
            await connection.start();
            await getOrder();
            console.log("SignalR bağlantısı kuruldu.");
          } catch (error) {
            console.error(
              "SignalR bağlantısı başlatılırken hata oluştu:",
              error
            );
            // Bağlantı başarısız olduğunda tekrar denemek için bir süre bekleyebilirsiniz
            toast.error("Bağlantı Koptu lütfen sayfanızı yenileyin");
            //  setTimeout(startConnection, 10000); // 5 saniye sonra tekrar dene
          }
          connection.onclose(() => {
            // Bağlantı kapandığında tekrar bağlanmaya çalış
            console.log(
              "SignalR bağlantısı kapandı. Yeniden bağlanmaya çalışılıyor..."
            );
            toast.error("Bağlantı Koptu lütfen sayfanızı yenileyin");
            // setTimeout(startConnection, 10000); // 5 saniye sonra tekrar dene
          });
          connection.on(OrderHubEnum.receiveOrder, (order) => {
            if (order.order.tableId === tableId) {
              dispatch(setOrder(order));
            }
            // Yeni siparişi işlemek için istediğiniz işlemleri yapabilirsiniz
          });
          connection.on(OrderHubEnum.removeOrderTable, (tableIdParams) => {
            if (tableIdParams === tableId) {
              dispatch(setOrder({} as any));
            }
          });
        };
        startConnection();
      }

      // Bağlantıyı başlat
    },
    [dispatch, getOrder, tableId]
  );
  const tooltipChange = useCallback((value: boolean) => {
    setTooltipVisble(value);
  }, []);
  const changeCategory = (categoryId: number) => {
    dispatch(categoryIdChange(categoryId));
  };
  useEffect(() => {
    if (companyId !== 0 && company?.orderAtTheTable === true) {
      setCount((ct) => {
        if (ct === 0) {
          getOrderHup(companyId);
          console.clear();
        }
        return 1;
      });
    }

    setTimeout(() => {
      tooltipChange(true);
      setTimeout(() => {
        tooltipChange(false);
      }, 2000);
    }, 500);

    return () => {};
  }, [company, companyId, dispatch, getOrderHup, tooltipChange]);

  return (
    <AppContext.Provider value={{ userStatus, setUserStatusTo }}>
      <div id="app" className={getStatusClass()}>
        <div className={categoryPageStatus === true ? "header2" : "header"}>
          <div
            onClick={() => {
              dispatch(setCategoryPageStatus(true));
            }}
            style={{
              position: "fixed",
              top: 0,
              width: "100%",
              justifyContent: "space-between",
              padding: 3,
              background:
                "linear-gradient(0deg, rgb(40 44 52 / 0%) 0%, rgb(17 0 32) 100%)",

              zIndex: 1000000,
              borderBottomStyle: "dashed",
              borderWidth: 2,
            }}
            className="info"
          >
            <CompanyLogoComponent />
          </div>
          {categoryPageStatus ? (
            <></>
          ) : (
            <CategoryComponent
              items={categoryList}
              clickSelect={changeCategory}
              clickFullProducts={() => dispatch(setCategoryPageStatus(true))}
            />
          )}
        </div>
        <Menu />

        <Background />
      </div>
      {company?.orderAtTheTable === true || company?.waiterCall === true ? (
        <div
          style={{
            position: "fixed",
            zIndex: 10000000,
            top: "77%",
            right: 11,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {company.orderAtTheTable === true ? (
            <Tooltip
              open={tooltipVisble}
              placement="left"
              title={t(I18nPagePropertyEnum.templateBasketFloat)}
            >
              <BasketComponent />
            </Tooltip>
          ) : (
            <></>
          )}
          {company?.waiterCall === true ? (
            <Tooltip
              open={tooltipVisble}
              placement="left"
              title={t(I18nPagePropertyEnum.templateWaiterFloat)}
            >
              <Popconfirm
                icon={
                  <i
                    style={{ color: "black", paddingRight: 5 }}
                    className="fa-duotone fa-user-chef fa-lg"
                  ></i>
                }
                placement="leftTop"
                title={t(I18nPagePropertyEnum.menuProductWaiter)}
                description={t(I18nPagePropertyEnum.menuProductCallTheWaiter)}
                okText={"Evet"}
                cancelText={t(I18nPagePropertyEnum.commonCancel)}
                onConfirm={async () => {
                  console.error(companyId, tableId);

                  const connection = new signalR.HubConnectionBuilder()
                    .withUrl(WaiterHubUrl(companyId).url)
                    .withAutomaticReconnect()
                    .configureLogging(signalR.LogLevel.Information)
                    .build();

                  try {
                    await connection
                      .start()
                      .then(() => {
                        console.log("SignalR bağlantısı kuruldu.WaiterHub");
                      })
                      .catch((error) => {
                        console.error(
                          "SignalR bağlantısı başlatılırken hata oluştu:",
                          error
                        );
                      });
                    await connection.invoke(
                      "CallTheWaiter",
                      companyId,
                      tableId
                    );
                  } catch (error) {
                  } finally {
                    connection.stop();
                  }
                }}
              >
                <Space
                  style={{
                    cursor: "pointer",

                    margin: 5,
                  }}
                >
                  <Avatar
                    style={{ backgroundColor: "white" }}
                    shape="square"
                    icon={
                      <i
                        style={{ color: "black" }}
                        className="fa-duotone fa-user-chef fa-lg"
                      ></i>
                    }
                  />
                </Space>
              </Popconfirm>
            </Tooltip>
          ) : (
            <></>
          )}
          {company.orderAtTheTable === true ? (
            <Tooltip
              open={tooltipVisble}
              placement="left"
              title={t(I18nPagePropertyEnum.templateTableFloat)}
            >
              <CompanyTableOrderPage />
            </Tooltip>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </AppContext.Provider>
  );
};

export default QrTemplate1Page;

interface MenuProps {}
