import { Row, Col, Card, Button } from "antd";
import Title from "antd/es/typography/Title";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MenuProductModel } from "../../../services/menu_products/models/menu_product.model";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyId } from "../../../react-reduxs/slices/auth/auth_slice";
import MenuCategoryService from "../../../services/menu_categories/menu_category.service";
import MenuProductServcie from "../../../services/menu_products/menu_product.service";
import { MenuCategoryModel } from "../../../services/menu_categories/models/menu_category.model";
import Template2CategoryList from "./Template2CategoryList";
import { getCompany } from "../../../react-reduxs/slices/companies/company_slice";
import { ApiUrl, OrderHubUrl } from "../../../consts/api";
import { convertToUnicode } from "../../../funcs/func";
import MenuProductBasketAddComponent from "../../../components/menu_products/MenuProductBasketAddComponent";
import MenuProductOrderButtonComponent from "../../../components/menu_products/MenuProductOrderButtonComponent";
import { getTableId } from "../../../react-reduxs/slices/tables/tableSlice";
import { MenuProductAmountType } from "../../../models/menu_product_amount_type";
import { OrderHubEnum } from "../../../enums/order_hub.enum";
import { setOrder } from "../../../react-reduxs/slices/orders/order_slice";
import { AppDispatch } from "../../../react-reduxs/configure_store";
import { OrderService } from "../../../services/orders/order.service";
import * as signalR from "@microsoft/signalr";
import { toast } from "react-toastify";

const Template2ProductList: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const [selectCategory, setSelectCategory] = useState<number>(0);
  const categoryService = useMemo(() => new MenuCategoryService(), []);
  const productService = useMemo(() => new MenuProductServcie(), []);
  const orderService = useMemo(() => new OrderService(), []);
  const companyId = useSelector(getCompanyId);
  const company = useSelector(getCompany);
  const menuProductListRef = useRef<MenuProductModel[]>([]);
  const [menuProductList, setMenuProductList] = useState<MenuProductModel[]>(
    []
  );

  const [menuCategoryList, setMenuCategoryList] = useState<MenuCategoryModel[]>(
    []
  );

  const [count, setCount] = useState<number>(0);

  const getProduct = useCallback(async () => {
    var response = await productService.getList(companyId);
    if (response) {
      menuProductListRef.current = response;
      setMenuProductList(response);
    } else {
      menuProductListRef.current = [];
      setMenuProductList([]);
    }
  }, [companyId, productService]);
  const getCategory = useCallback(async () => {
    var response = await categoryService.getList(companyId);
    if (response) {
      setMenuCategoryList(response);
    } else {
      setMenuCategoryList([]);
    }
  }, [categoryService, companyId]);



 

  const selectCategoryClick = useCallback((id: number) => {
    setSelectCategory(id);
    if (id !== 0) {
      setMenuProductList(
        menuProductListRef.current.filter((x) => x.menuCategoryId === id)
      );
    } else {
      setMenuProductList(menuProductListRef.current);
    }
  }, []);
  const tableId = useSelector(getTableId);
  const [menuProductAmountListState, setMenuProductAmountListState] = useState<
    MenuProductAmountType[]
  >([]);

  const getOrder = useCallback(async () => {
    try {
      let orderResponse = await orderService.getOrder(companyId, tableId ?? "");

      if (orderResponse) {
        dispatch(setOrder(orderResponse));
      } else {
        dispatch(setOrder({} as any));
      }
      console.error(orderResponse);
    } catch (error) {
      console.error("OrderService Qery", error);
    }
  }, [companyId, dispatch, orderService, tableId]);

  const getOrderHup = useCallback(
    async (companyId: number) => {
      console.error(companyId);
      if (companyId !== 0) {
        let connection = new signalR.HubConnectionBuilder()
          .withUrl(OrderHubUrl(companyId).url).withAutomaticReconnect()
          .build();

        const startConnection = async () => {
          try {
            await connection.start();
            await getOrder();
            console.log("SignalR bağlantısı kuruldu.");
          } catch (error) {
            console.error(
              "SignalR bağlantısı başlatılırken hata oluştu:",
              error
            );
            toast.error("Bağlantı Koptu lütfen sayfanızı yenileyin");
            // Bağlantı başarısız olduğunda tekrar denemek için bir süre bekleyebilirsiniz
          //  setTimeout(startConnection, 10000); // 5 saniye sonra tekrar dene
          }
          connection.onclose(() => {
            // Bağlantı kapandığında tekrar bağlanmaya çalış
            console.log(
              "SignalR bağlantısı kapandı. Yeniden bağlanmaya çalışılıyor..."
            );
            toast.error("Bağlantı Koptu lütfen sayfanızı yenileyin");
           // setTimeout(startConnection, 10000); // 5 saniye sonra tekrar dene
          });
          connection.on(OrderHubEnum.receiveOrder, (order) => {
            if (order.order.tableId === tableId) {
              dispatch(setOrder(order));
            }
            // Yeni siparişi işlemek için istediğiniz işlemleri yapabilirsiniz
          });
          connection.on(OrderHubEnum.removeOrderTable, (tableIdParams) => {
            if (tableIdParams === tableId) {
              dispatch(setOrder({} as any));
            }
          });
        };
        startConnection();
      }
      // Bağlantıyı başlat
    },
    [dispatch, getOrder, tableId]
  );
  const increment = useCallback(
    (menuProductId: number) => {
      setMenuProductAmountListState((prevMenuProductAmountList) => {
        const newMenuProductAmountList = prevMenuProductAmountList.map(
          (element) => {
            if (element.menuProductId === menuProductId) {
              return {
                ...element,
                amount: element.amount + 1,
              };
            }
            return element;
          }
        );
        // Eğer menuProductId listemizde yoksa, yeni bir öğe ekleyin
        if (
          !newMenuProductAmountList.some(
            (element) => element.menuProductId === menuProductId
          )
        ) {
          newMenuProductAmountList.push({
            menuProductId: menuProductId,
            amount: 2,
          });
        }
        return newMenuProductAmountList;
      });
    },
    [setMenuProductAmountListState]
  );
  const descrement = useCallback(
    (menuProductId: number) => {
      setMenuProductAmountListState((prevMenuProductAmountList) => {
        const newMenuProductAmountList = prevMenuProductAmountList.map(
          (element) => {
            if (element.menuProductId === menuProductId) {
              if (element.amount > 1) {
                return {
                  ...element,
                  amount: element.amount - 1,
                };
              }
            }
            return element;
          }
        );
        // Eğer menuProductId listemizde yoksa, yeni bir öğe ekleyin
        if (
          !newMenuProductAmountList.some(
            (element) => element.menuProductId === menuProductId
          )
        ) {
          newMenuProductAmountList.push({
            menuProductId: menuProductId,
            amount: 2,
          });
        }
        return newMenuProductAmountList;
      });
    },
    [setMenuProductAmountListState]
  );
  const removeMenuProductAmount = useCallback(
    (menuProductId: number) => {
      let newList = menuProductAmountListState.filter(
        (x) => x.menuProductId !== menuProductId
      );
      setMenuProductAmountListState(newList);
    },
    [menuProductAmountListState, setMenuProductAmountListState]
  );   
  const init = useCallback(async () => {
    await getProduct();
    await getCategory();
    await getOrderHup(companyId);
  }, [companyId, getCategory, getOrderHup, getProduct]);
  useEffect(() => {
    if (companyId !== 0) {
      setCount((cou) => {
        if (cou === 0) {
          init();
        }
        return 1;
      });
    }
    return () => {};
  }, [companyId, init]);
  return (
    <div>
      <Template2CategoryList
        selectedCategory={selectCategory}
        onSelect={selectCategoryClick}
        categoryList={menuCategoryList}
      />
      <div style={{ height: "100vh", overflow: "auto" }}>
        <Row>
          {menuProductList.flatMap((x) => (
            <Col key={x.id} flex={1}>
              <Card
                style={{
                  margin: 10,
                  maxHeight: 150,
                  minHeight: 150,
                  maxWidth: 400,
                  border: "1px solid #f0f0f000",
                  backgroundColor: "#ffffffa3",
                }}
                styles={{
                  body: {
                    padding: 10,
                  },
                }}
              >
                <Row>
                  <Col
                    flex={1}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      gap: 5,
                      maxWidth: "230px",
                    }}
                  >
                    <Title
                      style={{
                        fontSize: "13pt",
                        margin: 0,
                        fontWeight: "bold",
                        fontFamily: "GilroyRegular",
                        maxHeight: 40,
                        minHeight: 40,
                      }}
                    >
                      {x.title}
                    </Title>
                    <Title
                      style={{
                        fontSize: "10pt",
                        margin: 0,
                        fontWeight: "normal",
                        fontFamily: "GilroyRegular",
                        overflow: "auto",
                        maxHeight: 54,
                        minHeight: 54,
                      }}
                    >
                      {x.detail}
                    </Title>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 0,
                      }}
                    >
                      {/* <Title
                      style={{
                        fontSize: "10pt",
                        color: "#DFDFDF",
                        textDecoration: "line-through",
                        margin: 0,
                        fontWeight: "normal",
                        fontFamily: "RegaveDemibold",
                      }}
                    >
                      100 ₺
                    </Title> */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ display: "flex", gap: 5 }}>
                       
                         <Title
                            style={{
                              fontSize: "13pt",
                              color: "rgb(95 7 7 / 59%)",
                              margin: 0,
                              fontWeight: "bold",
                              fontFamily: "RegaveDemibold",
                              width:"100%"
                            }}
                          >
                            {x.price}{" "}
                            {convertToUnicode(company?.currency?.symbol ?? "")}
                          </Title>
                       
                          <MenuProductBasketAddComponent
                            menuProduct={x}
                            menuProductAmountList={menuProductAmountListState}
                            removeMenuProductAmount={removeMenuProductAmount}
                            tableId={tableId}
                          />
                          <MenuProductOrderButtonComponent
                            menuProduct={x}
                            removeMenuProductAmount={removeMenuProductAmount}
                            menuProductAmountList={menuProductAmountListState}
                            tableId={tableId}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <img
                      style={{ borderRadius: 10 }}
                      height={100}
                      width={100}
                      src={ApiUrl + "/" + x.photo}
                      alt=""
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default Template2ProductList;
