import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Button, Col, Layout, Menu, Row, Select, theme } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content, Header } from "antd/es/layout/layout";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useNavigation } from "react-router-dom";
import UserProfileComponent from "../../components/UserProfileComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompany,
  getCompanyList,
  setCompanyPublic,
} from "../../react-reduxs/slices/companies/company_slice";
import { DefaultOptionType } from "antd/es/select";
import { setCompanyId } from "../../react-reduxs/slices/auth/auth_slice";

const CompanyManagerLayout: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [collapsed, setCollapsed] = useState(false);
  const companyPublic = useSelector(getCompany);
  const companyList = useSelector(getCompanyList);

  const [companyOptionList, setCompanyOptionList] = useState<
    DefaultOptionType[]
  >([]);
  useEffect(() => {
    let list: DefaultOptionType[] = [];

    companyList.forEach((element) => {
      list.push({
        title:
          element.title +
          " " +
          (element.subheading === undefined ? "" : element.subheading),
        label:
          element.title +
          " " +
          (element.subheading === undefined ? "" : element.subheading),
        value: element.id,
      });
    });
    setCompanyOptionList(list);
    return () => {};
  }, [companyList]);
  

  
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Layout style={{ height: "100vh" }}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="demo-logo-vertical" />
        <Row style={{ margin: 4 }}>
          <Col>
            <img height={30} src="/images/Backlink.png" alt="" />
          </Col>
          <Col>
            <Title
              level={4}
              style={{
                margin: 0,
                padding: 0,
                color: "white",
                marginLeft: 10,
                fontFamily: "auto",
              }}
            >
              Link QRMenü
            </Title>
          </Col>
        </Row>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["1"]}
          items={[
            {
              key: "1",
              icon: <i className="fa-duotone fa-house"></i>,
              label: "Anasayfa",
              onClick: () => {
                navigate("/compManager/");
              },
            },
            {
              key: "2",
              icon: (
                <i className="fa-duotone fa-rectangle-history-circle-user"></i>
              ),
              label: "Kullanıcı List",
              onClick: () => {
                navigate("/compManager/company-user-list");
              },
            },
            {
              key: "3",
              icon: <i className="fa-brands fa-product-hunt"></i>,
              label: "Bölüm Listesi",
              onClick: () => {
                navigate("/compManager/section-list");
              },
            },
            {
              key: "4",
              icon: <i className="fa-duotone fa-tablet-rugged"></i>,
              label: "Masa Listesi",
              onClick: () => {
                navigate("/compManager/table-list");
              },
            },
            {
              key: "5",
              icon: <i className="fa-brands fa-product-hunt"></i>,
              label: "Ürün Listesi",
              onClick: () => {
                navigate("/compManager/menu-product-list");
              },
            },
            {
              key: "6",
              icon: <i className="fa-brands fa-product-hunt"></i>,
              label: "Kategori Listesi",
              onClick: () => {
                navigate("/compManager/menu-category-list");
              },
            },
          ]}
        />
      </Sider>
      <Layout>
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <Row>
            <Col
              flex={1}
              style={{ display: "flex", gap: 5, alignItems: "center" }}
            >
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: "16px",
                  width: 64,
                  height: 64,
                }}
              />
              <h4 style={{ position: "absolute", left: 65 }}>
                {companyPublic?.title +
                  " " +
                  (companyPublic?.subheading === undefined
                    ? ""
                    : companyPublic.subheading)}
              </h4>
            </Col>
            <Col style={{display:"flex", gap:4, alignItems:"center"}}>
              <h4 style={{height:26,margin:0,padding:0,lineHeight:"23px"}}>Firma Seç :</h4>
              <Select
                labelInValue
                defaultValue={{
                  value: companyPublic?.id,
                  label:
                    companyPublic?.title +
                    " " +
                    (companyPublic?.subheading === undefined
                      ? ""
                      : companyPublic?.subheading),
                }}
                style={{  marginRight:4}}
                onChange={async (value) => {
                  let item = companyList.find((x) => x.id === value.value);
                  if (item !== undefined) {
                    await dispatch(setCompanyPublic(item));
                    await dispatch(setCompanyId(item.id));
                    window.location.reload();
                  }
                  console.error(value);
                }}
                options={companyOptionList}
              />
            </Col>
            <Col style={{ display: "flex", alignItems: "center" }}>
              <UserProfileComponent />
            </Col>
          </Row>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default CompanyManagerLayout;
