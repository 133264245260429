import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../react-reduxs/configure_store";
import {
  getToken,
  setCompanyId,
  setToken,
} from "../../react-reduxs/slices/auth/auth_slice";
import { Button, Card, Col, Form, Input, List, Row, Select } from "antd";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";
import { I18nPagePropertyEnum } from "../../i18n/i18n_page_property";
import i18n from "i18next";
import "../../enums/languages/language.enum";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../../services/auth/auth.service";
import { LoginModel } from "../../services/auth/models/login.model";
import { UserTypeEnum } from "../../enums/user_type.enum";
import "./login.css";

import {
  getCompany,
  getCompanyList,
  setCompanyList,
  setCompanyPublic,
} from "../../react-reduxs/slices/companies/company_slice";
import "react-toastify/dist/ReactToastify.css";
import { ApiUrl } from "../../consts/api";
const LoginPage = () => {
  const authService = useMemo(() => new AuthService(), []);

  const [pageGo, setPageGo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const token = useSelector(getToken);
  const companyPublic = useSelector(getCompany);
  const companyList = useSelector(getCompanyList);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch: AppDispatch = useDispatch();
  const [languageList, seLanguageList] = useState<string[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const languageChange = useCallback((language: string) => {
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
  }, []);

  useEffect(() => {
    if (i18n.options.resources) {
      const resourceKeys = Object.keys(i18n.options.resources);
      seLanguageList(resourceKeys);
    }

    return () => {};
  }, []);
  useEffect(() => {
    if (token !== "" && pageGo) {
      const decodedToken: any = JSON.parse(atob(token.split(".")[1]));
      if (decodedToken?.userType === UserTypeEnum.Waiter) {
        navigate("/waiter");
      } else if (decodedToken?.userType === UserTypeEnum.Admin) {
        navigate("/admin");
      } else if (decodedToken?.userType === UserTypeEnum.CompanyManager) {
        navigate("/compManager");
      }
    }
    return () => {};
  }, [navigate, pageGo, token]);

  const onFinish = useCallback(
    async (values: LoginModel) => {
      setIsLoading(true);
      try {
        var response = await authService.login(values);
        if (response?.companies) {
          if (response.companies.length > 0) {
            dispatch(setCompanyList(response.companies));
            if (response.companies.length === 1) {
              dispatch(setCompanyId(response.companies[0].id));
              dispatch(setCompanyPublic(response.companies[0]));
              setPageGo(true);
            }
          } else {
            setPageGo(true);
          }
        }
        if (response?.token) {
          let decodeToken = JSON.parse(atob(response.token.split(".")[1]));
          if (decodeToken) {
            if (decodeToken.companyId) {
              dispatch(setCompanyId(decodeToken.companyId));
            }
          }
          await dispatch(setToken(response.token));
        }
      } catch (error) {
        // Token ayarlanamadı, uygun bir şekilde işlem yapılabilir (örneğin, kullanıcıya bir hata mesajı gösterilebilir)
      } finally {
        setIsLoading(false);
      }
    },
    [authService, dispatch]
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "rgb(238 254 255 / 80%)",
        }}
      >
        <Card className="login-content">
          <Row style={{ height: 50, marginBottom: 10 }}>
            <Col flex={1}>
              <img width={50} src="./images/Backlink.png" alt="" />
            </Col>
            <Col
              style={{
                paddingLeft: 10,
              }}
              flex={2}
            >
              <Title
                level={3}
                style={{
                  maxHeight: 3,
                  display: "flex",
                  alignItems: "center",

                  fontWeight: "normal",
                  fontFamily: "BoldGroovy",
                }}
              >
                Link {t(I18nPagePropertyEnum.loginTitle)}
              </Title>
            </Col>
          </Row>
          {companyList.length === 0 ? (
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                name="userName"
                label={`${t(I18nPagePropertyEnum.loginUserName)} / ${t(
                  I18nPagePropertyEnum.loginEmail
                )}`}
                hasFeedback
                rules={[{ required: true }, { type: "string", min: 2 }]}
              >
                <Input
                  placeholder={`${t(I18nPagePropertyEnum.loginUserName)} / ${t(
                    I18nPagePropertyEnum.loginEmail
                  )}`}
                />
              </Form.Item>
              <Form.Item
                name="password"
                label={`${t(I18nPagePropertyEnum.loginPassword)}`}
                hasFeedback
                rules={[{ required: true }, { type: "string", min: 6 }]}
              >
                <Input.Password
                  placeholder={`${t(I18nPagePropertyEnum.loginPassword)}`}
                />
              </Form.Item>
              <Form.Item
                label={`${t(I18nPagePropertyEnum.loginLanguage)}`}
                hasFeedback
                rules={[{ required: true }, { type: "string", min: 6 }]}
              >
                <Select
                  value={selectedLanguage}
                  onChange={(value) => languageChange(value)}
                  options={languageList.map((lang) => ({
                    label: lang.toText(),
                    value: lang,
                  }))}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  loading={isLoading}
                  disabled={isLoading}
                  icon={<i className="fa-duotone fa-arrow-right-from-arc"></i>}
                  style={{ fontWeight: "bold", minWidth: "100%" }}
                  htmlType="submit"
                >
                  {`${t(I18nPagePropertyEnum.loginLogin)}`}
                </Button>
              </Form.Item>
            </Form>
          ) : (
            <div style={{ textAlign: "center" }}>
              <Title level={5}>Firma Seçiniz </Title>
              <List
                grid={{
                  gutter: 16,
                  xs: 1,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2,
                  xxl: 2,
                }}
                dataSource={companyList}
                style={{
                  maxHeight: 400,
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
                renderItem={(item) => (
                  <List.Item>
                    <Card
                      title={item.title +" " + (item.subheading??"")}
                      actions={[
                        <Button
                          onClick={() => {
                            dispatch(setCompanyId(item.id));
                            dispatch(setCompanyPublic(item));
                            setPageGo(true);
                          }}
                        >
                          Firmayı Seç
                        </Button>,
                      ]}
                      styles={{
                        body: {
                          height: 140,
                        },
                      }}
                    >
                      <img
                        width={100}
                        src={ApiUrl + "/" + item.logo}
                        alt={item.logo}
                      />
                      <Title level={5}>{item.subheading}</Title>
                    </Card>
                  </List.Item>
                )}
              />
            </div>
          )}
        </Card>
      </div>
    </>
  );
};

export default LoginPage;
