import React, { useMemo } from "react";
import { UserService } from "../services/users/user.service";
import { Button, Col, Form, Input, Row, Space } from "antd";
import Title from "antd/es/typography/Title";
import { toast } from "react-toastify";

const PasswordReset = () => {
  const [form] = Form.useForm();
  const userService = useMemo(() => new UserService(), []);

  const onFinish = async (values: any) => {
    console.error(values);

    let response = await userService.myPasswordReset({
      oldPassword: values.oldPassword,
      password: values.password,
    });
    if (response) {
      toast.success("Kayıt Başarılı");
    }
  };

  return (
    <div>
      <Title level={3}>Şifre Yenile</Title>

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row style={{ gap: 3 }}>
          <Col span={16}>
            <Form.Item
              name="oldPassword"
              label="Eski Şifre"
              rules={[{ required: true }]}
            >
              <Input placeholder="Eski Şifre" />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item
              name="password"
              label="Şifre"
              rules={[
                { required: true },
                { min: 4, message: "Şifre en az 4 karakter olmalıdır!" },
              ]}
            >
              <Input placeholder="Şifre" />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item
              name="passwordConfirm"
              label="Şifre Tekrar"
              rules={[
                { required: true, message: "Lütfen şifrenizi tekrar giriniz!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Şifreler eşleşmiyor!"));
                  },
                }),
              ]}
            >
              <Input placeholder="Şifre Tekrar" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              Kaydet
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PasswordReset;
