import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CompanyPublicModel } from "../../../services/companies/models/company_public.model";
import { RootState } from "../../configure_store";

interface CompanyState {
  companyPublic?: CompanyPublicModel;
  copanyList: CompanyPublicModel[];
}

const initialState: CompanyState = {
  copanyList: [],
};
const companySlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setCompanyPublic: (
      state,
      action: PayloadAction<CompanyPublicModel | undefined>
    ) => {
      state.companyPublic = action.payload;
    },
    setCompanyList: (state, action: PayloadAction<CompanyPublicModel[]>) => {
      state.copanyList = action.payload;
    },
  },
});

export const { setCompanyPublic, setCompanyList } = companySlice.actions;

export const getCompany = (state: RootState) => state.company.companyPublic;
export const getCompanyList = (state: RootState) => state.company.copanyList;

const CompanyReducer = companySlice.reducer;
export default CompanyReducer;
