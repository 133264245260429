import { Col, Row } from "antd";
import Title from "antd/es/typography/Title";

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCompany } from "../../../react-reduxs/slices/companies/company_slice";
import { ApiUrl } from "../../../consts/api";
import { setCategoryPageStatus } from "../../../react-reduxs/slices/categories/category_slice";

const CompanyLogoComponent: React.FC = () => {
  const dispatch = useDispatch();
  const company = useSelector(getCompany);

  return (
    <div style={{ width: "100%" }}>
      <Row>
        <Col>
          <img
            onClick={() => {
              dispatch(setCategoryPageStatus(true));
            }}
            style={{
              borderRadius: 10,
              margin: "5px 5px 0px 5px",
              objectFit: "scale-down",
              minWidth: 80,
            }}
            width={80}
            height={65}
            alt=""
            src={
              company?.logo
                ? ApiUrl + "/" + company?.logo
                : "/images/Backlink.png"
            }
          />
        </Col>
        <Col
          flex={1}
          style={{ maxHeight: "69px", display: "flex", alignItems: "center" }}
        >
          <h1 className="head-title" >
            {company?.title}
          </h1>
          {company?.orderAtTheTable === true || company?.waiterCall === true ? (
            <Title
              style={{
                color: "white",
                marginLeft: 10,
                fontFamily: "BoldGroovy",
                fontWeight: "normal",
                position: "absolute",
                right: 11,
                top: 25,
              }}
              level={4}
            >
              {company?.tableName}
            </Title>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CompanyLogoComponent;
