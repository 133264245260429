import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { SectionService } from "../../../services/sections/serction.service";
import { SectionModel } from "../../../services/sections/models/section.model";
import { Badge, Card, Col, Row } from "antd";
import Meta from "antd/es/card/Meta";
import { TableService } from "../../../services/tables/table.service";
import { TableModel } from "../../../services/tables/models/tabele.model";
import Title from "antd/es/typography/Title";
import InfiniteScroll from "react-infinite-scroll-component";
import WaiterTableDetailOrderModal from "../components/tables/WaiterTableOrderModal";
import { OrderStateModel } from "../../../services/orders/models/order_state.model";
import { OrderService } from "../../../services/orders/order.service";
import * as signalR from "@microsoft/signalr";
import { WaiterHubs } from "../../../consts/SignaIR.const";
import { WaiterHubEnum } from "../../../enums/waiter_hub.enum";
import { WaiterModel } from "../../../models/waiter.model";
import { WaiterService } from "../../../services/waiters/waiter.service";
import { useSelector } from "react-redux";
import { getCompanyId } from "../../../react-reduxs/slices/auth/auth_slice";
import { OrderProductStatusModel } from "../../../services/order_product_statuses/models/order_product_status.model";
import { OrderProductStatusService } from "../../../services/order_product_statuses/order_product_status.service";
import { OrderProductService } from "../../../services/order_products/order_product.service";
import { OrderProductStatusEnum } from "../../../enums/orders/order_status.enum";
import { ApiUrl, OrderHubUrl } from "../../../consts/api";
import { toast } from "react-toastify";

const WaiterHomePage: React.FC = () => {
  const tableListRef = useRef<TableModel[]>([]);
  const sectionService = useMemo(() => new SectionService(), []);
  const tableService = useMemo(() => new TableService(), []);
  const orderService = useMemo(() => new OrderService(), []);
  const waiterService = useMemo(() => new WaiterService(), []);
  const orderProductService = useMemo(() => new OrderProductService(), []);
  const orderProductStatusService = useMemo(
    () => new OrderProductStatusService(),
    []
  );

  const [orderProductStatusList, setOrderProductStatusList] = useState<
    OrderProductStatusModel[]
  >([]);
  const companyId = useSelector(getCompanyId);

  const [count, setCount] = useState<number>(0);
  const [setsectionList, setSetsectionList] = useState<SectionModel[]>([]);
  const orderStateModelListRef = useRef<OrderStateModel[]>([]);
  const sectionListRef = useRef<SectionModel[]>([]);
  const [tableList, setTableList] = useState<TableModel[]>([]);
  const [setselectedSection, setSetselectedSection] = useState<number>(0);
  const [orderStateModelList, setOrderStateModelList] = useState<
    OrderStateModel[]
  >([]);
  const [hupStartedList, setHupStartedList] = useState<any[]>([]);
  const [waiterCallList, setWaiterCallList] = useState<WaiterModel[]>([]);

  const getOrder = useCallback(async () => {
    let orderResponse = await orderService.getOrderList(companyId);
    if (orderResponse) {
      setOrderStateModelList(orderResponse);
      orderStateModelListRef.current = orderResponse;
    }
  }, [companyId, orderService]);
  const getWaiterCall = useCallback(async () => {
    var response = await waiterService.getWaiterCallListByCompanyId(companyId);
    if (response) {
      setWaiterCallList(response);
    }
  }, [companyId, waiterService]);
  const getOrderProductStatusList = useCallback(async () => {
    var response = await orderProductStatusService.getList();
    if (response) {
      setOrderProductStatusList(response);
    }
  }, [orderProductStatusService]);

  const getSignaIRHub = useCallback(async () => {
    WaiterHubs.forEach(async (hup) => {
      let connection = new signalR.HubConnectionBuilder()
        .withAutomaticReconnect()
        .withUrl(hup.val(companyId).url) // Hub URL'nizi buraya ekleyin
        .build();
    
      const startConnection = async () => {
        try {
          await connection.start();
          await getOrder();
          await getWaiterCall();
          console.log("SignalR bağlantısı kuruldu.");
        } catch (error) {
          console.error("SignalR bağlantısı başlatılırken hata oluştu:", error);
          setTimeout(startConnection, 5000); // 5 saniye sonra tekrar dene
        }
      };
    
      connection.onclose(() => {
        console.log("SignalR bağlantısı kapandı, tekrar bağlanılıyor...");
        startConnection();
      });
    
      // SignalR bağlantısını başlat
      await startConnection();
    
      if (hup.name === WaiterHubEnum.order) {
        // ReceiveOrder event'ini dinle
        connection.on("WaiterOrderList", (order, type) => {
          setOrderStateModelList(order);
          orderStateModelListRef.current = order;
          console.log("Yeni sipariş alındı:", order);
          if (type === "call") {
            if ("Notification" in window) {
              if (Notification.permission === "granted") {
                new Notification("Sipariş!", {
                  body: "Yeni bir sipariş var.",
                  icon: "./logo192.png",
                });
              } else if (Notification.permission !== "denied") {
                Notification.requestPermission().then((permission) => {
                  if (permission === "granted") {
                    new Notification("Sipariş!", {
                      body: "Yeni bir sipariş var.",
                      icon: "./logo192.png",
                    });
                  }
                });
              }
            } else {
              alert("Yeni bir sipariş var.");
            }
          }
        });
      }
    
      if (hup.name === WaiterHubEnum.waiterCall) {
        connection.on(hup.val(companyId).event, (waiter, type) => {
          if (type === "call") {
            console.error(waiter, tableListRef, sectionListRef);
            let table = tableListRef.current.find(
              (x) => x.id === waiter[waiter.length - 1].tableId
            );
            let section = sectionListRef.current.find(
              (x) => x.id === table?.sectionId ?? 0
            );
            if ("Notification" in window) {
              if (Notification.permission === "granted") {
                new Notification("Garson Çağrı!", {
                  body: "Bölüm:" + section?.name + " Masa: " + table?.name ?? "",
                  icon: "./logo192.png",
                });
              } else if (Notification.permission !== "denied") {
                Notification.requestPermission().then((permission) => {
                  if (permission === "granted") {
                    new Notification("Garson Çağrı!", {
                      body: "Bölüm:" + section?.name + " Masa: " + table?.name ?? "",
                      icon: "./logo192.png",
                    });
                  }
                });
              }
            } else {
              alert("Bölüm: " + section?.name + " Masa: " + table?.name ?? "");
            }
          }
          setWaiterCallList(waiter);
          console.log("Waiter calling:", waiter);
        });
      }
      setHupStartedList([...hupStartedList, connection]);
    });
    
  }, [companyId, getOrder, getWaiterCall, hupStartedList]);
  const getTableList = useCallback(
    async (sectionId: number) => {
      let tableResponse = await tableService.getListByCompanyId(companyId);
      if (tableResponse.length > 0) {
        tableListRef.current = tableResponse;
        setTableList(tableResponse.filter((c) => c.sectionId === sectionId));
      }
    },
    [companyId, tableService]
  );
  const initialLoad = useCallback(async () => {
    try {
      if (companyId) {
        let sectionResponse = await sectionService.getListByCompanyId(
          companyId
        );
        if (sectionResponse.length > 0) {
          setSetsectionList(sectionResponse);
          sectionListRef.current = sectionResponse;
          setSetselectedSection(sectionResponse[0].id);
          await getTableList(sectionResponse[0].id);
          await getOrderProductStatusList();
          await getSignaIRHub();
        }
      }
    } catch (error) {}
  }, [
    companyId,
    getOrderProductStatusList,
    getSignaIRHub,
    getTableList,
    sectionService,
  ]);

  const selectSection = useCallback(
    async (sectionId: number) => {
      setTableList(
        tableListRef.current.filter((x) => x.sectionId === sectionId)
      );
      setSetselectedSection(sectionId);
    },
    [getTableList]
  );
  const updateOrderProductStatus = useCallback(
    async (
      tableIdP: string,
      orderProductId: number,
      orderProductStatusId: number
    ) => {
      const connection = new signalR.HubConnectionBuilder()
        .withUrl(OrderHubUrl(companyId).url)
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();

      try {
        await connection
          .start()
          .then(() => {
            console.log("SignalR bağlantısı kuruldu.WaiterHub");
          })
          .catch((error) => {
            console.error(
              "SignalR bağlantısı başlatılırken hata oluştu:",
              error
            );
          });
        await connection.invoke(
          "OrderProductStatusUpdate",
          parseInt(companyId.toString()),
          tableIdP,
          orderProductId,
          orderProductStatusId
        );
      } catch (error) {
      } finally {
        connection.stop();
      }
    },
    [companyId]
  );
  const getOrderCount = useCallback(
    (sectionId: number) => {
      let sum = 0;
      let tableL = tableListRef.current.filter(
        (x) => x.sectionId === sectionId
      );
      tableL.forEach((element) => {
        let order = orderStateModelList.find(
          (x) => x.order.tableId === element.id
        );
        let waiter = waiterCallList.filter((x) => x.tableId === element.id);
        if (waiter) {
          sum += waiter.length;
        }
        if (order) {
          sum += order.orderProductList.filter(
            (x) => x.status.text === OrderProductStatusEnum.pendingApproval
          ).length;
        }
      });
      return sum;
    },
    [orderStateModelList, waiterCallList]
  );

  useEffect(() => {
    setCount((ct) => {
      if (ct === 0) {
        initialLoad();
      }
      return 1;
    });
    try {
      if (Notification.permission === "default") {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            console.log("Bildirim izni verildi.");
          }
        });
      }
    } catch (error) {
      toast.error("Error"+error)
    }
    const checkSomething = () => {
      // const audioWaiterCall = new Audio("./music/orderCall.mp3");
      // console.error(orderStateModelListRef.current);

      if (
        orderStateModelListRef.current.filter(
          (x) =>
            x.orderProductList.findIndex(
              (x) => x.status.text === OrderProductStatusEnum.pendingApproval
            ) !== -1
        ).length > 0
      ) {
        // audioWaiterCall.play().catch((err) => {
        //   console.error(err);
        // });
        new Notification("Sipariş!", {
          body: "Onay bekleyen siparişler var.",
          icon: "./logo192.png",
        });
      }
    };
    const intervalId = setInterval(checkSomething, 5 * 60 * 1000);
    return () => {
      clearInterval(intervalId);
      hupStartedList.forEach(async (hub) => {
        await hub.stop();
      });
    };
  }, [count]);

  return (
    <div>
      <Row
        style={{
          overflow: "auto",
          whiteSpace: "nowrap",
          flexFlow: "nowrap",
          paddingTop: 10,
        }}
      >
        {setsectionList.flatMap((x) => (
          <Badge key={x.id} count={getOrderCount(x.id)}>
            <Col style={{ marginLeft: 20 }} key={x.id}>
              <Card
                onClick={() => {
                  selectSection(x.id);
                }}
                style={{
                  width: 115,
                  cursor: "pointer",
                  backgroundColor:
                    setselectedSection === x.id ? "#79CEF0CB" : "#699ac94a",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: 10,
                }}
                cover={
                  <img
                    style={{ width: 50, height: 50 }}
                    alt="logo"
                    src={
                      x.logo === "" || x.logo === undefined
                        ? "/images/sections.png"
                        : ApiUrl + "/" + x.logo
                    }
                  />
                }
              >
                <Meta title={x.name} />
              </Card>
            </Col>
          </Badge>
        ))}
      </Row>
      <Title level={5}>Masalar</Title>
      <div style={{ overflow: "auto", maxHeight: "calc(100vh - 255px)" }}>
        <InfiniteScroll
          next={() => {}}
          dataLength={tableList.length}
          hasMore={true}
          loader
        >
          <Row style={{ marginTop: 10 }}>
            {tableList.flatMap((x) => (
              <Col
                flex={1}
                key={x.id}
                style={{ marginRight: 10, marginBottom: 20, width: 150 }}
              >
                <WaiterTableDetailOrderModal
                  updateOrderProductStatus={updateOrderProductStatus}
                  orderProductStatusList={orderProductStatusList}
                  waiterModel={waiterCallList.find((y) => y.tableId === x.id)}
                  item={x}
                  order={orderStateModelList.find(
                    (or) => or.order.tableId === x.id
                  )}
                />
              </Col>
            ))}
          </Row>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default WaiterHomePage;
