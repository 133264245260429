import BaseApiService from "../base_api.service";
import { SectionModel } from "./models/section.model";

export class SectionService extends BaseApiService {
  /**
   *
   */
  constructor() {
    super("Sections");
  }

  async getListByCompanyId(compnayId: number): Promise<SectionModel[]> {
    return (
      (await this.get<SectionModel[]>(
        `/GetListByCompanyId?companyId=${compnayId}`
      )) ?? []
    );
  }
  add(sectionModel: SectionModel): Promise<SectionModel | undefined> {
    return this.post<SectionModel>(``, sectionModel);
  }
  update(sectionModel: SectionModel): Promise<SectionModel | undefined> {
    return this.put<SectionModel>(``, sectionModel);
  }
  deleteById(id: number): Promise<string | undefined> {
    return this.delete<string>(`?id=${id}`);
  }
}
