import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../configure_store";
import { MenuProductModel } from "../../../services/menu_products/models/menu_product.model";

interface ProductState {
  productList: MenuProductModel[];
  selectedCategoryId: number;
}

const initialState: ProductState = {
  productList: [],
  selectedCategoryId: 0,
};
const productSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setProductList: (state, action: PayloadAction<MenuProductModel[]>) => {
      state.productList = action.payload;
    },
    categoryIdChange: (state, action: PayloadAction<number>) => {
      state.selectedCategoryId = action.payload;
    },
  },
});

export const { setProductList ,categoryIdChange} = productSlice.actions;

export const getProductList = (state: RootState) => state.product.productList;
export const getProductSelectedCategoryId = (state: RootState) => state.product.selectedCategoryId;
const ProductReducer = productSlice.reducer;
export default ProductReducer;
