import { Button, Modal, Popconfirm, Table, TableColumnsType } from "antd";
import Title from "antd/es/typography/Title";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MenuCategoryModel } from "../../../services/menu_categories/models/menu_category.model";
import MenuCategoryService from "../../../services/menu_categories/menu_category.service";
import { useSelector } from "react-redux";
import { getCompanyId } from "../../../react-reduxs/slices/auth/auth_slice";
import { toast } from "react-toastify";
import { ApiUrl } from "../../../consts/api";
import dayjs from "dayjs";
import CompManagerMenuCategoryAdd from "./CompManagerMenuCategoryAdd";
import { FileUploadService } from "../../../services/file_uploads/file_upload.service";

const CompanyManagerMenuCategoryList = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [categoryList, setCategoryList] = useState<MenuCategoryModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const companyId = useSelector(getCompanyId);
  const menuCategoryService = useMemo(() => new MenuCategoryService(), []);
  const fileUploadService = useMemo(() => new FileUploadService(), []);
  const categoryModel = useRef<MenuCategoryModel>();
  const getCategory = useCallback(async () => {
    let response = await menuCategoryService.getList(companyId);

    if (response) {
      setCategoryList(response);
    }
  }, [companyId, menuCategoryService]);
  const init = useCallback(async () => {
    setIsLoading(true);
    await getCategory();
    setIsLoading(false);
  }, [getCategory]);
  useEffect(() => {
    setCount((c) => {
      if (c === 0) {
        init();
      }
      return 1;
    });

    return () => {};
  }, [init]);

  const saveCategory = useCallback(
    async (menuCategoryModel: MenuCategoryModel) => {
      setIsLoading(true);
      if (menuCategoryModel.id === 0 || menuCategoryModel.id === undefined) {
        let response = await menuCategoryService.add(menuCategoryModel);
        if (response) {
          toast("Kayıt işlemi başarılı.");

          await getCategory();
        }
      } else {
        let response = await menuCategoryService.update(menuCategoryModel);
        if (response) {
          toast("Kayıt işlemi başarılı.");

          await getCategory();
        }
      }
      setIsLoading(false);
    },
    [getCategory, menuCategoryService]
  );

  const columns: TableColumnsType<MenuCategoryModel> = [
    {
      width: 100,
      title: "Fotoğraf",
      dataIndex: "photo",
      render: (value) => {
        return (
          <img
            width={70}
            height={70}
            src={ApiUrl + "/" + value ?? "/images/product.png"}
            alt=""
          />
        );
      },
    },

    {
      title: "Adı",
      dataIndex: "name",
    },
    {
      title: "Ekleme Zamanı",
      dataIndex: "createdDate",
      render: (value) => {
        return <div>{dayjs(value).format("DD/MM/YYYY HH:mm")}</div>;
      },
    },
    {
      title: "Güncellenme Zamanı",
      dataIndex: "updatedDate",
    },

    {
      width: 50,
      title: "",
      dataIndex: "id",
      render: (value, record) => {
        return (
          <Button
            loading={isLoading}
            disabled={isLoading}
            type="text"
            onClick={() => {
              categoryModel.current = record;
              setModalVisible(true);
            }}
            style={{ backgroundColor: "#C4F3F7B2" }}
            icon={
              <i
                style={{ color: "green", fontSize: "large" }}
                className="fa-duotone fa-pen-to-square"
              ></i>
            }
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "id",
      width: 70,
      render(value, record) {
        return (
          <Popconfirm
            placement="left"
            title={"Kayıdı silinecek onaylıyormusunuz ?"}
            okText="Evet"
            cancelText="Hayır"
            onConfirm={async () => {
              setIsLoading(true);
              var response = await menuCategoryService.deleteCategory(value);
              if (response) {
                if (record.photo !== undefined && record.photo !== "") {
                  await fileUploadService.deleteFile(record.photo);
                }
                toast.success("Silme başarılı");
              }
              await getCategory();
              setIsLoading(false);
            }}
          >
            <Button
              loading={isLoading}
              disabled={isLoading}
              style={{marginRight:10}}
              danger
              icon={<i className="fa-duotone fa-trash"></i>}
            />
          </Popconfirm>
        );
      },
    },
  ];
  return (
    <div>
      <Title level={3} style={{ color: "#4A5967", textAlign: "center" }}>
        Ürün Kategori Listesi
      </Title>
      <Button
        loading={isLoading}
        onClick={() => {
          setModalVisible(true);
          categoryModel.current = undefined;
        }}
        size="small"
        style={{ margin: 10 }}
        icon={<i className="fa-solid fa-rectangle-history-circle-plus"></i>}
      >
        Yeni Kategori Ekle
      </Button>
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={categoryList}
        rowKey={"id"}
        pagination={{ pageSize: 10 }}
        virtual={true}
        scroll={{
          x: "100%",
          y: "calc(100vh - 200px)",
        }}
      />
      {modalVisible ? (
        <Modal footer={<></>} open={modalVisible}>
          <CompManagerMenuCategoryAdd
            isLoading={isLoading}
            saveCategory={saveCategory}
            categoryModel={categoryModel.current}
            modalClose={() => {
              setModalVisible(false);
            }}
          />
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CompanyManagerMenuCategoryList;
