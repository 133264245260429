import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
} from "antd";
import Title from "antd/es/typography/Title";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SectionModel } from "../../../services/sections/models/section.model";
import { TableModel } from "../../../services/tables/models/tabele.model";
import CompManagerSectionAdd from "../sections/CompManagerSectionAdd";
import { useSelector } from "react-redux";
import { getCompanyId } from "../../../react-reduxs/slices/auth/auth_slice";
import FileUploadComponent from "../../tests/FileUploadComponent";
import { getCompany } from "../../../react-reduxs/slices/companies/company_slice";
import { TableService } from "../../../services/tables/table.service";
import { ApiUrl } from "../../../consts/api";
import { I18nPagePropertyEnum } from "../../../i18n/i18n_page_property";
import { useTranslation } from "react-i18next";
import { FileUploadService } from "../../../services/file_uploads/file_upload.service";
import { toast } from "react-toastify";
interface CompManagerTableAddProps {
  id?: string;
  sectionList: SectionModel[];
  add: (value: TableModel) => void;
  addSection: (value: SectionModel) => void;
  getTableList?: () => void;
  tableService: TableService;
  isLoading: boolean;
}
const CompManagerTableAdd: React.FC<CompManagerTableAddProps> = ({
  sectionList,
  add,
  addSection,
  id,
  tableService,
  getTableList,
  isLoading,
}) => {
  const { t } = useTranslation();
  const fileUploadService = useMemo(() => new FileUploadService(), []);
  const companyId = useSelector(getCompanyId);
  const company = useSelector(getCompany);
  const [modalSectionAdd, setModalSectionAdd] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState("");
  const [count, setCount] = useState(0);
  const [form] = Form.useForm();
  const onFinish = (values: any) => {
    if (companyId) {
      add({
        companyId: companyId,
        logo: imageUrl === "" ? undefined : imageUrl,
        ...values,
      });
    }
  };
  const getTable = useCallback(async () => {
    let response = await tableService.getById(id ?? "");
    if (response) {
      form.setFieldValue("id", response.id);
      form.setFieldValue("name", response.name);
      form.setFieldValue("number", response.number);
      form.setFieldValue("sectionId", response.sectionId);
      setImageUrl(response.logo);
    }
  }, [form, id, tableService]);
  const initialLoad = useCallback(() => {
    if (id !== "" && id !== undefined) {
      getTable();
    }
  }, [getTable, id]);

  useEffect(() => {
    setCount((cou) => {
      if (cou === 0) {
        initialLoad();
      }
      return 1;
    });
    return () => {};
  }, [initialLoad]);

  return (
    <div>
      <Title level={3}>Masa Ekle</Title>

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row style={{ gap: 3 }}>
          <Col flex={1}>
            <Form.Item name="id" label="" initialValue={id ?? ""}>
              <Input style={{ display: "none" }} placeholder="" />
            </Form.Item>
          </Col>
          <Col flex={1}>
            {imageUrl === "" || imageUrl === undefined ? (
              <FileUploadComponent
                setImageUrlParam={setImageUrl}
                path={"Companies/" + company?.folderName + "/Tables"}
              />
            ) : (
              <>
                <Popconfirm
                  placement="left"
                  okText={"Evet"}
                  onConfirm={async () => {
                    let response = await fileUploadService.deleteFile(imageUrl);

                    if (response) {
                      toast.success(response);
                      let response2 = await tableService.update({
                        ...form.getFieldsValue(),
                        logo: null,
                      });
                      setImageUrl("");
                      if (response2) {
                        toast.success("Kayıt işlemi başarılı");
                        if (getTableList !== undefined) {
                          await getTableList();
                        }
                      }
                    }
                  }}
                  cancelText="Hayır"
                  title="Fotoğraf silme işlemini onaylıyor musunuz?"
                >
                  <Button
                    loading={isLoading}
                    disabled={isLoading}
                    danger
                    size="small"
                    shape="circle"
                    icon={<i className="fa-duotone fa-trash"></i>}
                  />
                </Popconfirm>

                <img width={100} src={ApiUrl + "/" + imageUrl} alt="" />
              </>
            )}
          </Col>
          <Col flex={1}>
            <Form.Item name="name" label="Adı" rules={[{ required: true }]}>
              <Input placeholder="Adı" />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item
              name="number"
              label="Masa Numarası"
              rules={[{ required: true }]}
            >
              <Input placeholder="Masa Numarası" />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item
              name="sectionId"
              label="Bölüm "
              rules={[{ required: true }]}
            >
              <Select
                placeholder=" "
                style={{ width: "100%", minWidth: 150 }}
                options={sectionList.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
              />
            </Form.Item>
          </Col>
          <Col
            flex={1}
            style={{ display: "flex", alignItems: "center", paddingTop: 7 }}
          >
            <Button
              onClick={() => {
                setModalSectionAdd(true);
              }}
              loading={isLoading}
              disabled={isLoading}
              icon={<i className="fa-duotone fa-plus"></i>}
            >
              Bölüm Ekle
            </Button>
          </Col>
        </Row>
        <Form.Item>
          <Space>
            <Button
              loading={isLoading}
              disabled={isLoading}
              type="primary"
              htmlType="submit"
            >
              Kaydet
            </Button>
          </Space>
        </Form.Item>
      </Form>
      {modalSectionAdd ? (
        <Modal
          title=""
          open={modalSectionAdd}
          okText={undefined}
          cancelText={undefined}
          onCancel={() => setModalSectionAdd(false)}
          styles={{ mask: { backdropFilter: "blur(10px)" } }}
          footer={<></>}
        >
          <CompManagerSectionAdd
            closeModal={() => {
              setModalSectionAdd(false);
            }}
            isLoading={isLoading}
            addSection={addSection}
          />
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CompManagerTableAdd;
