import React from "react";
import "./ui_card.scss";
import LazyImage from "../images/LayzImage";
interface UICardProps {
  imageSrc: string;
  title: string;
  description?: string;
  price: string;
  Footer?: React.ReactNode;
  Duraiton?: React.ReactNode;
  RigthIcon?: React.ReactNode;
  OnClick?: () => void;
}
const UICard: React.FC<UICardProps> = ({
  imageSrc,
  title,
  price,
  Footer = <></>,
  description,
  Duraiton = <></>,
  RigthIcon = <></>,
  OnClick = () => {},
}) => {
  return (
    <div className="nft">
      <div style={{ position: "absolute", right: 2, top: 2 }}>{RigthIcon}</div>
      <div className="main" onClick={() => OnClick()}>
        {/* <img src={imageSrc} alt="NFT" className="tokenImage"/> */}
        <LazyImage className="tokenImage" src={imageSrc} alt="NFT" />
        <h2 className="ui-title">{title}</h2>
        <p className="description">{description}</p>
        <div className="tokenInfo">
          <div className="price">
            <p>{price}</p>
          </div>
          <div className="duration">{Duraiton}</div>
        </div>
        <hr />
      </div>
      <div className="creator">{Footer}</div>
    </div>
  );
};

export default UICard;
