import { Button, Col, Form, Input, Row, Space } from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { SectionModel } from "../../../services/sections/models/section.model";
import { useSelector } from "react-redux";
import { getCompanyId } from "../../../react-reduxs/slices/auth/auth_slice";
import FileUploadComponent from "../../tests/FileUploadComponent";
import { getCompany } from "../../../react-reduxs/slices/companies/company_slice";
import { ApiUrl } from "../../../consts/api";

interface CompManagerSectionAddProps {
  sectionModel?: SectionModel;
  addSection: (value: SectionModel) => void;
  isLoading: boolean;
  closeModal: () => void;
  deleteMenuSectionImage?: (
    path: string,
    sectionModel: SectionModel
  ) => Promise<SectionModel | undefined>;
}

const CompManagerSectionAdd: React.FC<CompManagerSectionAddProps> = ({
  addSection,
  sectionModel,
  isLoading,
  closeModal,
  deleteMenuSectionImage,
}) => {
  const companyId = useSelector(getCompanyId);
  const company = useSelector(getCompany);
  const [form] = Form.useForm();
  const onFinish = (values: any) => {
    if (companyId) {
      addSection({
        compnayId: companyId,
        name: values.name,
        id: sectionModel?.id ?? 0,
        logo: imageUrl === "" ? undefined : imageUrl,
      });
    }
  };
  const [imageUrl, setImageUrl] = useState("");
  useEffect(() => {
    if (sectionModel) {
      form.setFieldValue("id", sectionModel.id);
      form.setFieldValue("name", sectionModel.name);
      setImageUrl(sectionModel.logo ?? "");
    }
    return () => {};
  }, [form, sectionModel]);

  return (
    <div>
      <Title level={3}>Bölüm Ekle</Title>

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item name="id" label="">
          <Input placeholder="" style={{ display: "none" }} />
        </Form.Item>
        <Row style={{ gap: 3 }}>
          <Col flex={1}>
            <Form.Item name="name" label="Adı" rules={[{ required: true }]}>
              <Input placeholder="Adı" />
            </Form.Item>
          </Col>
          <Col flex={1}>
            {sectionModel?.logo !== "" && sectionModel?.logo !== undefined ? (
              <div>
                <Button
                  size="small"
                  style={{ position: "absolute", left: 80 }}
                  loading={isLoading}
                  onClick={async () => {
                    if (sectionModel?.logo) {
                      console.error(sectionModel);

                      if (
                        sectionModel?.logo !== undefined &&
                        sectionModel?.logo !== ""
                      ) {
                        if (deleteMenuSectionImage !== undefined) {
                          let response = await deleteMenuSectionImage(
                            sectionModel?.logo,
                            sectionModel
                          );
                          if (response) {
                            setImageUrl("");
                            sectionModel = response;
                          }
                        }
                      }
                    }
                  }}
                  danger
                  icon={<i className="fa-duotone fa-trash"></i>}
                />
                <img
                  width={110}
                  height={110}
                  src={ApiUrl + "/" + imageUrl}
                  alt=""
                />
              </div>
            ) : (
              <FileUploadComponent
                path={"Companies/" + company?.folderName + "/Sections"}
                setImageUrlParam={setImageUrl}
              />
            )}
          </Col>
        </Row>
        <Form.Item>
          <Space>
            <Row style={{ gap: 5 }}>
              <Col>
                <Button
                  loading={isLoading}
                  disabled={isLoading}
                  type="primary"
                  htmlType="submit"
                >
                  Kaydet
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    closeModal();
                  }}
                  danger
                  loading={isLoading}
                  disabled={isLoading}
                  type="primary"
                >
                  Kapat
                </Button>
              </Col>
            </Row>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CompManagerSectionAdd;
