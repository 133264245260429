import React, { useRef } from "react";
import { IMenuSectionProps } from "../../theme/components/interfaces/horizantel_scrollable.interface";
import HorizantelScrollable from "../../theme/components/HorizantelScrollable";
import { MenuCategoryModel } from "../../services/menu_categories/models/menu_category.model";
import { ApiUrl } from "../../consts/api";
import "./category.css";
import { useSelector } from "react-redux";
import { getProductSelectedCategoryId } from "../../react-reduxs/slices/products/product_slice";
const CategoryMenuSection: React.FC<IMenuSectionProps> = (
  props: IMenuSectionProps
) => {
  const targetRef = useRef<HTMLDivElement>(null);

  const getContent = (): JSX.Element => {
    if (props.scrollable) {
      return (
        <HorizantelScrollable
          ref={targetRef.current}
          style={{ flexWrap: "nowrap", marginBottom: 3 }}
          className="menu-section-content"
        >
          {props.children}
        </HorizantelScrollable>
      );
    }

    return <div className="menu-section-content">{props.children}</div>;
  };

  return (
    <div id={props.id} className="menu-section" style={{maxWidth:'100%',marginTop:82}}>
      <div className="menu-section-title">
        <i className={props.icon} />
        <span className="menu-section-title-text">{props.title}</span>
      </div>
      {getContent()}
    </div>
  );
};

const CategoryComponent = ({
  items = [] as MenuCategoryModel[],
  clickSelect = (categoryId: number) => {},
  clickFullProducts = () => {},
}) => {
  const categoryId = useSelector(getProductSelectedCategoryId);

  return (
    <CategoryMenuSection icon="" id="tools-section" scrollable title="">
     
      {items.flatMap((x) => {
        const styles: React.CSSProperties = {
          backgroundColor: x.photo
            ? x.photo.search("/color:/")
              ? x.photo.replaceAll("color:", "")
              : "transparent"
            : "transparent",
          backgroundImage: `url(${
            x.photo
              ? x.photo.search("/image:/")
                ? ApiUrl + "/" + x.photo.replaceAll("image:", "")
                : "https://www.kargem.online/dosyalar/2020/03/Kategori-Yo%CC%88netimi.jpg"
              : "https://www.kargem.online/dosyalar/2020/03/Kategori-Yo%CC%88netimi.jpg"
          })`,
        };

        return (
          <div key={x.id} onClick={() => clickSelect(x.id)}>
            <div
              className={
                x.id === categoryId ? "tool-card active-category" : "tool-card"
              }
              style={{ minWidth: 150 }}
            >
              <div
                className="tool-card-background background-image"
                style={styles}
              />
              <div
                className="tool-card-content"
                style={{ width: "100%", height: "100%" }}
              >
                <div className="tool-card-content-header">
                  <span
                    style={{
                      fontFamily: "Sofia",
                      fontSize: 20,
                      fontWeight: "bold",
                      position: "absolute",
                      bottom: 0,
                      backgroundColor: "#5252528c",
                      width: "-webkit-fill-available",
                      left: 0,
                      textAlign: "center",
                    }}
                    className="tool-card-name"
                  >
                    {x.name}
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </CategoryMenuSection>
  );
};

export default CategoryComponent;
